.wrapper {
  position: fixed;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 1001;
  height: 90px;
  background: #ffffff;
  display: flex;
  justify-content: center;
}

.tab {
  width: 1200px;
  height: 100%;
  display: flex;
}

.logoContainer {
  height: 100%;
  width: 200px;
  padding-top: 30px;
}

.tabsContainer {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.ul {
  height: 68px;
  line-height: 68px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
  display: flex;
}

.liBase {
  margin-left: 20px;
  padding: 0 10px;
  vertical-align: top;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  height: 100%;
  text-align: center;
  cursor: pointer;
  list-style: none;
  font-size: 15px;
  user-select: none;
}

.li {
  composes: liBase;
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
}
.liActive {
  composes: liBase;
  border-color: #ff6a00;
  color: #ff6a00;
}
