.wrapper {
  padding-top: 90px;
  display: flex;
  justify-content: center;
}

.poster {
  width: 100%;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main {
  width: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 32px;
  line-height: 45px;
}

.underline {
  width: 80px;
  height: 2px;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.05);
}

.aboutContentContainer {
  width: 790px;
}

.aboutContent {
}

.itemText {
  text-align: center;
  margin-top: 14px;
  margin-bottom: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 18px;
}

.imageContent {
  width: 1200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contactContainer {
  display: flex;
  flex-direction: column;
}

.contact {
  width: 380px;
  height: 52px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beian {
  width: 600px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
